import React from 'react'	
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage'	
import Layout from '../../components/Layout'	

class ProjectIndexPage extends React.Component {	
  render() {	
    const { data } = this.props
    const projects = data.allMarkdownRemark.edges

    return (	
      <Layout>	
        <div	
          className="full-width-image-container margin-top-0"	
          style={{	
            backgroundImage: `url('/img/projects-hero.jpg')`,	
            backgroundPosition: `center center`,	
          }}	
        >	
          <h1	
            className="has-text-weight-bold is-size-1"	
            style={{	
              boxShadow: '0.5rem 0 0 #076950, -0.5rem 0 0 #076950',	
              backgroundColor: '#076950',	
              color: 'white',	
              padding: '1rem',	
            }}	
          >	
            Personal projects
          </h1>	
        </div>	
        <section className="section">	
          <div className="container">	
            <div className="content">	
              <div className="is-multiline">
              {projects &&
          projects.map(({ node: project }) => (
            <div className="column is-10 is-offset-1" key={project.id}>
              <article
                className={`blog-list-item project-list-item tile is-child box`}
              >
                <header>
                  {project.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: project.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${project.frontmatter.title}`,
                          style: { borderRadius: '5px 5px 0px 0px' }
                        }}
                      />
                      {project.frontmatter.inProgress ? (
                        <div className="ribbon">
                            <span>In progress</span>
                        </div>
                        ) : null}
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-3"
                      to={project.fields.slug}
                    >
                      {project.frontmatter.title}
                    </Link>
                    <span className="subtitle is-block">
                      {project.frontmatter.date}
                    </span>
                  </p>
                </header>
                <div className="blog-list-item-content">
                  <p>
                    {project.frontmatter.description}
                    <br />
                    <Link className="button" to={project.fields.slug}>
                      Keep Reading →
                    </Link>
                  </p>
                </div>
              </article>
            </div>
          ))}
              </div>	
            </div>	
          </div>	
        </section>	
      </Layout>	
    )	
  }	
}

ProjectIndexPage.propTypes = {
    data: PropTypes.shape({
      allMarkdownRemark: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  }

  export default () => (
    <StaticQuery
      query={graphql`
  query projectPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            inProgress
            isFeatured
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
  `}
  render={(data) => <ProjectIndexPage data={data} />}
/>
)